import React from 'react'
import styles from './Button.module.scss'

export default ({
  variant = 'primary',
  type,
  href,
  external,
  size,
  fullWidth,
  children,
  onClick,
  rightIcon,
  leftIcon,
  className
}) => {
  const buttonClassNames = `
    ${styles.button} 
    ${variant === 'secondary' ? styles.secondary : ''}
    ${variant === 'outline' ? styles.outline : ''}
    ${variant === 'minimal' ? styles.minimal : ''}
    ${variant === 'inverse' ? styles.inverse : ''}
    ${size === 'xl' ? styles.xl : ''}
    ${fullWidth ? styles.fullWidth : ''}
    ${className ? ` ${className}` : ''}
  `

  return onClick ? (
    <button
      className={buttonClassNames}
      type={type || 'button'}
      onClick={onClick}
    >
      {leftIcon && (
        <span className={`${styles.icon} ${leftIcon ? styles.left : ''}`}>
          {leftIcon}
        </span>
      )}
      {children}
      {rightIcon && <span className={styles.icon}>{rightIcon}</span>}
    </button>
  ) : (
    <a
      href={href}
      className={buttonClassNames}
      target={external ? '_blank' : '_self'}
    >
      {leftIcon && (
        <span className={`${styles.icon} ${leftIcon ? styles.left : ''}`}>
          {leftIcon}
        </span>
      )}
      {children}
      {rightIcon && <span className={styles.icon}>{rightIcon}</span>}
    </a>
  )
}
